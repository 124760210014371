@font-face {
    font-family: "proxima-nova";
    src: url("../Proxima-Nova-Reg.ttf");
}

.App {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
}

.Home {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
}

.container {
    /* display: flex;
        flex-direction: column;
        align-items: center; */
    padding-left: 3vmin;
    padding-right: 3vmin;
    padding-bottom: 3vmin;
    margin-left: 1vmin;
    margin-right: 1vmin;
    margin-bottom: 1vmin;
}

.logo {
    font-family: "proxima-nova";
    color: black;
    font-size: calc(30px + 1.4vmin);
    line-height: calc(30px + 1.5vmin);
    font-weight: 700;
    text-decoration: none;
    margin-right: 200px;
}

.menuItem {
    text-decoration: none;
    margin-top: 30px;
    display: inline-block;
    position: relative;
    color: black;
}

.menuItem::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.menuItem:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.caret {
    height: calc(10px + 1.5vmin);
}

.diagonalArrow {
    height: calc(1.2vmin);
    padding-left: 5px;
}

.headshot {
    align-self: start;
    height: 55vh;
}

.postPhoto {
    width: 100%;
}

.postPhotoInRow {
    max-height: 50vh;
}

.flyer {
    max-height: 80vh;
}

.photoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    justify-content: space-between;
}

.post {
    max-width: 50vw;
}

.postDivider {
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: 1px dashed #bfbfbf;
}

.postTitle {
    font-size: calc(12px + 1.6vmin);
}

.paragraph {
    padding-top: 10px;
    padding-bottom: 10px;
}

.photoCredit {
    font-size: 1.8vmin;
    align-self: flex-end;
}

.photoCreditLink {
    color: black;
    text-decoration: none;
    width: min-content;
    font-size: 1.8vmin;
    align-self: flex-end;
}

.photoCreditRow {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
}

.photoColumn {
    display: flex;
    flex-direction: column;
}

.aboutMe {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    padding-left: 3vmin;
    padding-right: 3vmin;
    padding-bottom: 3vmin;
    margin-right: 1vmin;
    margin-bottom: 1vmin;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: calc(2 * (20px + 1.4vmin));
    padding: 3vmin;
    padding-right: 0;
    margin-left: 1vmin;
    margin-bottom: 1vmin;
    /* margin-right: 5vmin; */
}

button {
    border-style: none;
    border-width: 0px;
    background-color: transparent;
    font: 100 calc(8px + 1.4vmin) Helvetica;
    text-align: left;
    padding: 0;
    cursor: pointer;
}

.header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 1.4vmin);
    padding: 2vmin;
    margin: 1vmin;
}

.link {
    color: black;
}

.socialLink {
    color: black;
    text-decoration: none;
    width: min-content;
}

.linkWithArrow {
    padding-right: 6vmin;
}

.linkNoUnderline {
    color: rgb(0, 191, 255);
    text-decoration: none;
}

a.link:hover {
    background-color: rgb(245, 245, 245);
}

a.linkNoUnderline:hover {
    background-color: rgb(245, 245, 245);
}

a.logo:hover {
    background-color: none;
}

ul {
    list-style-type: none;
}

/* Mobile */
@media screen and (max-width: 1200px) {
    .aboutMe {
        flex-wrap: wrap;
    }

    .postPhoto {
        width: 100%;
    }

    .postPhotoInRow {
        max-width: 100%;
    }

    .flyer {
        max-width: 100%;
    }

    .photoRow {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        justify-content: space-around;
    }

    .column {
        margin-left: 1vmin;
    }

    .post {
        max-width: 100%;
        padding: 6vmin;
        margin: 1vmin;
    }

    .postDivider {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .header {
        font-size: calc(10px + 1.6vmin);
    }

    .App {
        font-weight: 300;
    }

    .Home {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
    }

    .diagonalArrow {
        height: calc(1.8vmin);
    }

    .headshot {
        height: 60vh;
    }

    .photoCredit {
        font-size: 2vmin;
    }

    .photoCreditLink {
        font-size: 2vmin;
    }

    .menu {
        width: 100%;
        flex-direction: row;
        padding-top: 1vmin;
        padding-bottom: 1vmin;
        margin: 1vmin;
        margin-right: 0;
        flex-wrap: wrap;
    }

    .menuItem {
        margin-top: 0;
        margin-left: 40px;
    }

    button {
        font: 100 calc(12px + 1.4vmin) Helvetica;
    }

    .logo {
        margin-right: 0;
        font-size: calc(25px + 1.4vmin);
        line-height: calc(16px + 1.5vmin);
    }

    .aboutMe {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 885px) {
    .postPhotoInRow {
        max-height: 40vh;
    }

    .menu {
        padding-top: 2vmin;
        padding-bottom: 2vmin;
    }
}

@media screen and (max-width: 700px) {
    .postPhotoInRow {
        max-height: 25vh;
    }

    .menu {
        padding-top: 4vmin;
        padding-bottom: 4vmin;
    }
}

@media screen and (max-width: 446px) {
    .postPhotoInRow {
        max-height: 20vh;
    }
}
